import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'dialog-image-perdil',
  templateUrl: 'dialog-image-perfil.component.html',
  styleUrls: ['dialog-image-perfil.component.css']
})
export class DialogImagePerfil implements OnInit {
  @Output() onOk = new EventEmitter();

  showButton = false;
  showButtonSave = false;
  labelButton;
  imagem = {
    id: '',
    conteudo: '/assets/images/profile2.png'
  }
  imagemAux;

  constructor(
    public dialogRef: MatDialogRef<DialogImagePerfil>,
    public dialog: MatDialog
  ) { }


  ngOnInit() {
    this.imagemAux = this.imagem.conteudo;
    this.labelButton = this.imagem.id ? 'Alterar' : 'Adicionar';
  }

  excluirImage() {
    Swal.fire({
      title: 'Atenção!',
      text: "Você realmente quer excluir a foto do Perfil?",
      showCancelButton: true,
      confirmButtonText: 'Sim quero!',
      cancelButtonText: 'Cancelar',
    }).then(r => {
      if (r.value) {
        this.onOk.emit({ obj: '', dialog: this.dialogRef });
      }
    });
  }

  salvarImage() {
    this.onOk.emit({ obj: this.imagemAux, dialog: this.dialogRef });
  }

  changeListener($event): void {
    let file = <File>$event.target.files[0];
    this.readThis(file);
  }

  readThis(file): void {
    let myReader: FileReader = new FileReader();

    if (file) {
      myReader.onloadend = (e) => {
        this.imagemAux = myReader.result as string;
        this.showButtonSave = true;
      }
      myReader.readAsDataURL(file);
    }
  }

  close() {
    this.dialogRef.close();
  }

}