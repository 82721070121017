import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AcessoComponent } from './acesso/acesso.component';
import { MapaPublicComponent } from './mapa-public/mapa-public.component';
import { MapaComponent } from './mapa/mapa.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FullComponent } from './layouts/full/full.component';
import { PontoComponent } from './ponto/ponto.component';
import { ForgotComponent } from './forgot/forgot.component';


const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/ponto',
        pathMatch: 'full'
      },
      {
        path: 'ponto',
        component: PontoComponent,
        data: {
          urls: [
            { title: 'Pontos' }
          ]
        }
      },
      {
        path: 'mapa',
        component: MapaComponent,
        data: {
          urls: [
            { title: 'Mapa' }
          ]
        }
      },
      {
        path: 'mapa/:id',
        component: MapaComponent,
        data: {
          urls: [
            { title: 'Pontes', url: '/ponto' },
            { title: 'Mapa' }
          ]
        }
      },
      {
        path: 'acesso',
        component: AcessoComponent,
        canActivate: [AuthGuard],
        data: {
          urls: [
            { title: 'Acesso' }
          ]
        }
      }
    ]
  },
  { path: 'mapa-publico', component: MapaPublicComponent },
  { path: 'login', component: LoginComponent },
  { path: 'criar-conta', component: RegisterComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent }
  // { path: '**', component: PaginaNaoEncontradaComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
