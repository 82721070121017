import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { take } from "rxjs/operators";

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class ImagemService {

    constructor(private http: HttpClient){}

    listFindPonte(ponteId): Observable<any> {
        return this.http.get(`${SERVICE}/imagem/listFindPonte/${ponteId}`).pipe(take(1));
    }

    listFindPontoCritico(pontoCriticoId): Observable<any> {
        return this.http.get(`${SERVICE}/imagem/listFindPontoCritico/${pontoCriticoId}`).pipe(take(1));
    }

}