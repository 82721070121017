import { EventEmitter, Input, Output } from '@angular/core';
import { Message } from './../message/message.component';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogMessage } from 'src/app/shared/dialog-message/dialog-message';
import { UsuarioService } from 'src/app/services/usuario.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  
  @Output() readMessageEmitter = new EventEmitter();
  @Input() messages: Message[] = [];
  lengthMessages;
  @Output() newImageEmitter = new EventEmitter();
  @Input() email;
  @Input() imagem = {
    id: '',
    conteudo: '/assets/images/users/profile2.png'
  }

  constructor(
    public dialog: MatDialog
  ) { }


  readMessage(event) {
    if (!event.open) {
      this.readMessageEmitter.emit(event.id);
    }

    const dialogRef = this.dialog.open(DialogMessage, {
      height: 'auto',
      panelClass: 'modal-message'
    });
    dialogRef.componentInstance.message = event;
  }

  newImage() {
    this.newImageEmitter.emit();
  }

}
