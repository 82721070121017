import { UsuarioService } from 'src/app/services/usuario.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AlertService } from './services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    admin = false;

    constructor(
        private router: Router,
        private alert: AlertService,
        private usuarioService: UsuarioService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('access_token') != null) {
            await this.usuarioService.getUsuarioLogado().toPromise().then(r => {
                this.admin = r.admin;
            }, error => {
                this.alert.openError(error.message);
            });

            if(state.url === '/acesso') {
                if (this.admin)
                    return true;
            } else return true;

            this.router.navigate(['/']);
            this.alert.openError('Você não possui permissão para acessar esta página.');
            return false;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
