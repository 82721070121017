import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    @Output() loadingEmitter = new EventEmitter();
    
    constructor() { }

    loading(value) {
        this.loadingEmitter.emit(value);
    }

}