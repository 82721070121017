import { MensagemService } from "./../../services/mensagem.service";
import { Component } from "@angular/core";
import { Message } from "../components/message/message.component";
import { MatDialog } from "@angular/material";
import { UsuarioService } from "src/app/services/usuario.service";
import { DialogImagePerfil } from "src/app/shared/dialog-image-perfil/dialog-image-perfil.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [],
})
export class HeaderComponent {
  messages: Message[] = [];
  lengthMessages;

  user = {
    name: "",
    email: "",
  };

  imagem = {
    id: "",
    conteudo: "/assets/images/profile2.png",
  };

  constructor(
    private mensagemService: MensagemService,
    private dialog: MatDialog,
    private usuarioService: UsuarioService
  ) {}

  getlengthMessages(event) {
    this.lengthMessages = event;
  }

  ngOnInit(): void {
    let name = localStorage.getItem("nome");
    let lastName = localStorage.getItem("sobrenome");
    this.user.name = `${name ? name[0].toUpperCase() + name.slice(1) : ""} ${
      lastName ? lastName[0].toUpperCase() + lastName.slice(1) : ""
    }`;
    this.user.email = localStorage.getItem("login");
    this.getMsg();
    this.getImagem();
    setInterval(() => {
      this.getMsg();
    }, 600000);
  }

  getImagem() {
    this.usuarioService.getImagemUsuario(this.user.email).subscribe(
      (i) => {
        this.imagem = i
          ? i
          : { id: "", conteudo: "/assets/images/profile2.png" };
      },
      (error) => {
        console.log(error);
        this.imagem = { id: "", conteudo: "/assets/images/profile2.png" };
      }
    );
  }

  getMsg() {
    if (this.user.email) {
      this.mensagemService.show(this.user.email).subscribe((r) => {
        this.messages = r.map((msg, i) => {
          let message: Message = {} as Message;
          message.id = msg.id;
          message.content = msg.mensagem;
          message.date = new Date(msg.dataMensagem);
          message.open = msg.dataLeitura ? true : false;
          message.title = msg.titulo ? msg.titulo : `Mensagem ${i + 1}`;
          message.subtitle = msg.subtitle ? msg.subtitle : `Mensagem ${i + 1}`;
          return message;
        });
        this.lengthMessages = this.messages.filter((i) => !i.open).length;
      });
    }
  }

  readMessage(event) {
    this.mensagemService.ler(event).subscribe(() => {
      this.getMsg();
    });
  }
}
