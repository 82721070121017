import { DialogDetalhePonto } from './dialog-detalhe-ponto/dialog-detalhe-ponto';
import { DialogMessage } from './dialog-message/dialog-message';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogImageComponent } from './dialog-image/dialog-image.component';
import { MatIconModule, MatButtonModule, MatDialogModule, MatProgressSpinnerModule, MatCheckboxModule } from '@angular/material';
import { DialogImagePerfil } from './dialog-image-perfil/dialog-image-perfil.component';
import { SpinnerComponent } from './components/spinner.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    DialogImageComponent,
    DialogDetalhePonto,
    DialogMessage,
    DialogImagePerfil,
    SpinnerComponent
  ],
  entryComponents: [
    DialogImageComponent,
    DialogDetalhePonto,
    DialogMessage,
    DialogImagePerfil
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    FlexLayoutModule,
    MatProgressSpinnerModule
  ],
  exports: [
   SpinnerComponent 
  ],
  providers: []
})
export class SharedModule {}
