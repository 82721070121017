import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { take } from "rxjs/operators";

const SERVICE = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PontoService {
  constructor(private http: HttpClient) {}

  findById(id): Observable<any> {
    return this.http.get(`${SERVICE}/ponte/${id}`).pipe(take(1));
  }

  listAll(): Observable<any> {
    return this.http.get(`${SERVICE}/ponte`).pipe(take(1));
  }

  listRodovias(): Observable<any> {
    return this.http.get(`${SERVICE}/ponte/listRodovias`).pipe(take(1));
  }

  listPontesFindPontoCritico(pontoCriticoId): Observable<any> {
    return this.http
      .get(`${SERVICE}/ponte/listPontesFindPontoCritico/${pontoCriticoId}`)
      .pipe(take(1));
  }

  filterPontos(param): Observable<any> {
    return this.http.post(`${SERVICE}/ponte/filter`, param).pipe(take(1));
  }

  alterPublic(param): Observable<any> {
    return this.http.post(`${SERVICE}/ponte/alterPublic`, param).pipe(take(1));
  }

  filterMunicipio(desc): Observable<any> {
    return this.http.post(`${SERVICE}/municipio/filter`, desc).pipe(take(1));
  }

  exportExcel(parm): Observable<any> {
    return this.http.post(`${SERVICE}/ponte/export/excel`, parm, {
      observe: "response",
      responseType: "blob",
    });
  }

  exportDoc(parm): Observable<any> {
    return this.http.post(`${SERVICE}/ponte/export/doc`, parm, {
      observe: "response",
      responseType: "blob",
    });
  }

  deletePontoCritico(pontoCriticoId): Observable<any> {
    return this.http
      .get(`${SERVICE}/ponte/delete/${pontoCriticoId}`)
      .pipe(take(1));
  }
}
