import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private http: HttpClient) { }

    sendMessage(email): Observable<any> {
        return this.http.post(`${SERVICE}/reset-password/send-message`, email);
    }

    checkId(id): Observable<any> {
        return this.http.get(`${SERVICE}/reset-password/check-id/${id}`);
    }

    resetPass(change, id): Observable<any> {
        return this.http.post(`${SERVICE}/reset-password/reset-pass/${id}`, change);
    }

    alterarSenha(change): Observable<any> {
        return this.http.post(`${SERVICE}/reset-password/change-pass`, change);
    }

}