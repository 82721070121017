import { AlertService } from './../services/alert.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';

const email = new FormControl('', Validators.required);
const password = new FormControl('', Validators.required);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  labelBtn = 'Login';
  public form: FormGroup;
  constructor(
    private alert: AlertService,
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    email.setValue(localStorage.getItem('login'));
    localStorage.clear();
    this.form = this.fb.group({
      email: email,
      password: password
    });
  }

  logar() {
    this.labelBtn = 'Logando...';
    this.loginService.getToken(this.form.value).subscribe(r => {
      this.labelBtn = 'Login';
      if (r.error != null) {
        console.log(r.error);
        this.alert.openError(r.error == 'unauthorized' ? 'Acesso não autorizado' : r.error);
        localStorage.clear();
      } else if (r.access_token) {
        localStorage.setItem('access_token', r.access_token);
        localStorage.setItem('nome', r.extra.name);
        localStorage.setItem('sobrenome', r.extra.sobrenome);
        localStorage.setItem('login', r.login);
        this.router.navigate(['/']);
      }
    }, error => {
      this.labelBtn = 'Login';
      this.alert.openError("Ocorreu um Erro, não foi possível logar.");
      console.log(error);
    });
  }

}
