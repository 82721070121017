import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { take } from "rxjs/operators";

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private http: HttpClient, private router: Router){}

    getToken(credential: any): Observable<any> {
        return this.http.post(`${SERVICE}/login/logar`, credential).pipe(take(1));
    }

    alterarSenha(alterarSenhaDto: any): Observable<any> {
        return this.http.post(`${SERVICE}/integracao/changePassword`, alterarSenhaDto).pipe(take(1));
    }

}