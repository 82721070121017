import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { LoadingService } from '../services/loading.service';
import { UsuarioService } from '../services/usuario.service';


interface UsuarioRefactor {
  nome,
  login,
  editor,
  ativo,
  admin,
  object
}


@Component({
  selector: 'app-acesso',
  templateUrl: './acesso.component.html',
  styleUrls: ['./acesso.component.css']
})
export class AcessoComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = ['nome', 'login', 'ativo', 'editor', 'admin'];

  constructor(
    private alert: AlertService,
    private router: Router,
    private usuarioService: UsuarioService,
    private loadingService: LoadingService
  ) { }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.listAll();

    this.usuarioService.permissoesAlteradas.subscribe(() => {
      this.usuarioService.getUsuarioLogado().subscribe(r => {
        if (!r.admin) this.router.navigate(['/']);
        if (!r.ativo) this.router.navigate(['/login']);
      });
    });
  }

  listAll() {
    this.loadingService.loading(true);
    this.usuarioService.listAll().subscribe(r => {
      this.loadingService.loading(false);
      this.dataSource.data = this.refactor(r);
    }, error => {
      console.log(error);
      this.loadingService.loading(false);
      this.alert.openError('Ocorreu um Erro ao buscar os dados! Tente novamente mais tarde...');
    });
  }

  refactor(list) {
    return list.map(i => {
      let usuario: UsuarioRefactor = {} as UsuarioRefactor;
      usuario.nome = i.nome + " " + i.sobrenome;
      usuario.login = i.login;
      usuario.ativo = i.ativo;
      usuario.editor = i.editor;
      usuario.admin = i.admin;
      usuario.object = i;
      return usuario;
    });
  }

  alterUser(usuario) {
    usuario.object.ativo = usuario.ativo;
    usuario.object.editor = usuario.editor;
    usuario.object.admin = usuario.admin;
    this.loadingService.loading(true);
    this.usuarioService.save(usuario.object).subscribe(() => {
      this.loadingService.loading(false);
      this.usuarioService.permissoesAlteradas.emit();
    }, error => {
      console.log(error);
      this.loadingService.loading(false);
      this.alert.openError('Ocorreu um Erro ao buscar os dados! Tente novamente mais tarde...');
      this.listAll();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
