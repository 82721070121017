import { LoadingService } from './../services/loading.service';
import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-bing-layer';
import 'leaflet-draw';
import drawLocales from 'leaflet-draw-locales';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { icon, Marker } from 'leaflet';
import { AlertService } from '../services/alert.service';
import { GeoService } from '../services/geo.service';
import { PontoService } from '../services/ponto.service';
import { DialogDetalhePonto } from '../shared/dialog-detalhe-ponto/dialog-detalhe-ponto';
const iconRetinaUrl = 'assets/images/marker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  private map;
  pontos;
  centro;
  private static fazendas;
  private static markes = [];

  constructor(
    public dialog: MatDialog,
    private geoService: GeoService,
    private location: Location,
    private ponteService: PontoService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private alert: AlertService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      if (id) {
        let ponto = JSON.parse(localStorage.getItem('ponto'));
        if (ponto) {
          this.pontos = [ponto];
          this.centro = [ponto.latitude, ponto.longitude];
          this.initMap();
        } else {
          this.centro = [-16.690659, -49.260736];
          this.initMap();
        }
      } else {
        let pontos = JSON.parse(localStorage.getItem('pontos'));
        if (pontos) {
          this.pontos = pontos;
        }
        this.centro = [-16.690659, -49.260736];
        this.initMap();
      }
    });
  }

  private initMap(): void {
    this.map = new L.Map('map', { preferCanvas: true, center: new L.LatLng(this.centro[0], this.centro[1]), zoom: 12 });
    var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    var osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });
    var drawnItems = L.featureGroup().addTo(this.map);

    L.control.layers({
      'Rodovias': osm,
      'Bing': (L as any).tileLayer.bing('AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L').addTo(this.map),
      "Google": L.tileLayer('http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', { attribution: 'google' }).addTo(this.map),
    },
      { 'Marcação': drawnItems },
      { position: 'topleft', collapsed: false }
    ).addTo(this.map);

    if (this.pontos) {
      this.pontos.forEach(p => {
        let lat = p.latitude;
        let lng = p.longitude;

        if (lat && lng) {
          let latlng: [number, number] = [lat, lng];
          drawnItems.addLayer(MapaComponent.createPoint(latlng, p, this.ponteService, this.geoService, this.loadingService, this.map, this.dialog));
        }
      });
    }
    // else {
    //   // setTimeout(() => { this.loadingService.loading(true); });
    //   this.ponteService.listAll().subscribe(r => {
    //     this.pontos = r;

    //     this.pontos.forEach(p => {
    //       let lat = p.dados.latitude;
    //       let lng = p.dados.longitude;

    //       if (lat && lng) {
    //         let latlng: [number, number] = [lat, lng];
    //         drawnItems.addLayer(MapaComponent.createPoint(latlng, p, this.dialog));
    //       }
    //     });
    //     // this.loadingService.loading(false);
    //   }, error => {
    //     console.log(error);
    //     // this.loadingService.loading(false);
    //     this.alert.openError('Ocorreu um Erro ao buscar os dados! Tente novamente mais tarde...');
    //   });
    // }

    var layers = (drawnItems as any)._layers;
    var centros: any = MapaComponent.getCenter(layers, this.centro[0], this.centro[1]);
    this.map.panTo(centros);

    const locale = drawLocales('pt');
    locale.draw.toolbar.buttons.polygon = 'Awesome polygon!';
    locale.edit.toolbar.actions.save.text = 'Gravar';
    locale.draw.toolbar.buttons.polygon = 'Criar polígono';
    (L as any).drawLocal = locale;

    this.map.on('draw:created', function (event) {
      var layer = event.layer;
      drawnItems.addLayer(layer);
    });

    //adicao das rodovias no mapa
    let mapLocal = this.map;
    this.geoService.getRodovia().subscribe(r => {
      for (let geojson of r) {
        let layer = MapaComponent.createLayersFromJson(geojson);
        layer.on('mouseover', e => {
          var popup = L.popup().setLatLng((<any>e).latlng).setContent(geojson.properties.nome).openOn(mapLocal);
        });
        drawnItems.addLayer(layer);
      }
    });
  }

  static getCenter(layers, latitude, longitude) {
    var centros = [];
    for (let key in layers) {
      var layer = layers[key];
      if (layer._mRadius) {
        centros.push(layer._latlng);
      }
    }
    var polygon = L.polygon(centros, {});
    if (polygon != null && Object.keys(polygon.getBounds()).length > 0) {
      let centro = polygon.getBounds().getCenter();
      return centro;
    } else {
      return [latitude, longitude];
    }
  }

  static createPoint(latlng, ponto, ponteService, geoService, loadingService, map, dialog) {
    var iconRed = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    });

    var iconBlue = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    });

    let point = L.marker(latlng, {

    }).on('click', () => {
      loadingService.loading(true);

      if (MapaComponent.fazendas != null)
        map.removeLayer(MapaComponent.fazendas);

      MapaComponent.markes.forEach(i => i.setIcon(iconBlue));
      point.setIcon(iconRed);

      ponteService.listPontesFindPontoCritico(ponto.id).subscribe(r => {
        loadingService.loading(false);
        const dialogRef = dialog.open(DialogDetalhePonto, {
          height: 'auto',
          width: '70%'
        });
        dialogRef.componentInstance.ponto = ponto;
        dialogRef.componentInstance.relatos = r;

        let analise: any = localStorage.getItem("analise");
        if (analise != null) {
          analise = JSON.parse(analise);

          if (analise.pontoCriticoId == ponto.id) {
            dialogRef.componentInstance.analise = analise.economico;
            MapaComponent.fazendas = L.geoJSON(analise.car).addTo(map);
          }
        }

        dialogRef.componentInstance.analiseEmitter.subscribe(() => {
          let analise: any = localStorage.getItem("analise");
          if (analise != null) {
            analise = JSON.parse(analise);
            MapaComponent.fazendas = L.geoJSON(analise.car).addTo(map);
          }
        });
      }, error => {
        console.log(error);
        loadingService.loading(false);
      });
    });
    MapaComponent.markes.push(point);
    return point;
  }

  adicionarDiasData(date: Date, dias): Date {
    var hoje: Date = date;
    var dataVenc: Date = new Date(hoje.getTime() + (dias * 24 * 60 * 60 * 1000));
    return new Date(dataVenc.getFullYear() + '-' + (dataVenc.getMonth() + 1) + '-' + dataVenc.getDate());
  }

  close() {
    this.location.back();
  }

  static createLayersFromJson(geojson) {
    //inverte as coordenadas
    let coordinates = [];
    for (let coord of geojson.coordinates) {
      for (let lnglat of coord) {
        let latlng = [lnglat[1], lnglat[0]];
        coordinates.push(latlng);
      }
    }
    let polygon = L.polyline(coordinates, {
      stroke: true,
      color: "#FFFFFF",
      weight: 3,
    });
    return polygon;
  }

}
