import { EventEmitter, OnInit } from '@angular/core';
import {
  Component, Input, Output,
} from '@angular/core';

export interface Message {
  id: number;
  open: boolean;
  title: string;
  subtitle: string;
  content: string;
  date: Date;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html'
})
export class MessageComponent {
  
  @Input() message: Message;
  @Output() readMessageEmitter = new EventEmitter;

  clicked = false;

  constructor( ) { }


  readMessage() {
    this.readMessageEmitter.emit(this.message);
  }

}
