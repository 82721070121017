import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import Swal from 'sweetalert2';
import { ResetPasswordService } from '../services/reset-password.service';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  labelBtn = 'Solicitar';
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private resetPasswordService: ResetPasswordService
  ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ]
    });
  }

  onSubmit() {
    this.labelBtn = 'Solicitando...';
    this.resetPasswordService.sendMessage(this.form.value.email).subscribe(() => {
      this.labelBtn = 'Solicitar';
      Swal.fire(
        'Tudo certo',
        `Em instantes ${this.form.value.email} receberá um email com o link para a Redefinição da Senha.`
      ).then(() => {
        this.router.navigate(['/login']);
      });
    }, error => {
      console.log(error);
      this.labelBtn = 'Solicitar';
      Swal.fire(
        'Ops!',
        `${error.error.message}`
      );
    });
  }
}
