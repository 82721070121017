import { take } from 'rxjs/operators';
import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    permissoesAlteradas = new EventEmitter();
    
    constructor(private http: HttpClient){}


    public save(usuario): Observable<any> {
        return this.http.post(`${SERVICE}/usuario`, usuario).pipe(take(1));
    }

    public saveImageUsuario(email, imagem): Observable<any> {
        return this.http.post(`${SERVICE}/usuario/imagem/${email}`, imagem).pipe(take(1));
    }

    public deleteImageUsuario(email, id): Observable<any> {
        return this.http.delete(`${SERVICE}/usuario/imagem/delete/${email}/${id}`).pipe(take(1));
    }

    public getImagemUsuario(email): Observable<any> {
        return this.http.get(`${SERVICE}/usuario/imagem/${email}`).pipe(take(1));
    }

    listAll(): Observable<any> {
        return this.http.get(`${SERVICE}/usuario`).pipe(take(1)).pipe(take(1));
    }

    getUsuarioLogado(): Observable<any> {
        return this.http.get(`${SERVICE}/usuario/getUsuarioLogado`).pipe(take(1));
    }
    
}