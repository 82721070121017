import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AlertService } from '../services/alert.service';
import { ResetPasswordService } from '../services/reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  labelBtn = 'Redefinir';
  newPassword;
  confirmPassword;
  id;

  change = {
    login: '',
    newPass: ''
  }

  constructor(
    private alert: AlertService,
    private resetPasswordService: ResetPasswordService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.resetPasswordService.checkId(this.id).subscribe(r => {
      this.change.login = r.email;
    }, err => {
      Swal.fire(
        'Ops!',
        `${err.error.message}`
      ).then(()=> {
        this.router.navigate(['/login']);
      })
    });
  }

  save() {
    this.labelBtn = 'Redefinindo...';
    this.change.newPass = this.newPassword;
    this.resetPasswordService.resetPass(this.change, this.id).subscribe(r => {
      this.labelBtn = 'Redefinir';
      Swal.fire(
        'Tudo certo',
        'Sua senha foi redefinida com Sucesso.'
      ).then(()=> {
        this.router.navigate(['/login']);
      });
    }, error => {
      let msg = error.error.message ? error.error.message : 'Ocorreu um Erro, não foi possível redefinir sua senha.';
      this.labelBtn = 'Redefinir';
      this.alert.openError(msg);
    })
  }

}
