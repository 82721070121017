import { Component } from '@angular/core';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loading;
  
  constructor(
    private loadingService: LoadingService
  ) {}
  
  ngOnInit() {
    this.loadingService.loadingEmitter.subscribe(r => {
      setTimeout(() => {
        this.loading = r;
      });
    });
  }

}
