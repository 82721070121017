import { PontoService } from "./../../services/ponto.service";
import { LoadingService } from "../../services/loading.service";
import { ImagemService } from "../../services/imagem.service";
import { Component, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { AlertService } from "../../services/alert.service";
import { DialogImageComponent } from "../dialog-image/dialog-image.component";
import { GeoService } from "src/app/services/geo.service";

@Component({
  selector: "app-dialog-detalhe-ponto",
  templateUrl: "dialog-detalhe-ponto.html",
  styleUrls: ["dialog-detalhe-ponto.css"],
})
export class DialogDetalhePonto {
  @Output() analiseEmitter = new EventEmitter();
  ponto;
  relatos;
  analise;

  constructor(
    public dialogRef: MatDialogRef<DialogDetalhePonto>,
    public loadingService: LoadingService,
    private alert: AlertService,
    private imagemService: ImagemService,
    private geoService: GeoService,
    private pontoService: PontoService,
    public dialog: MatDialog
  ) {}

  openAnalise() {
    this.close();
    this.loadingService.loading(true);
    this.geoService.getCar(this.ponto.latitude, this.ponto.longitude).subscribe(
      (r) => {
        let analiseAux: any = r;
        analiseAux.pontoCriticoId = this.ponto.id;
        analiseAux.economico.qtd = analiseAux.qtd;
        localStorage.setItem("analise", JSON.stringify(analiseAux));
        this.analise = analiseAux.economico;
        this.loadingService.loading(false);
        this.analiseEmitter.emit();

        const dialogRef = this.dialog.open(DialogDetalhePonto, {
          height: "auto",
          minWidth: "70%",
          panelClass: "custom-dialog",
          disableClose: true,
        });
        dialogRef.componentInstance.ponto = this.ponto;
        dialogRef.componentInstance.relatos = this.relatos;
        let analise: any = localStorage.getItem("analise");
        if (analise != null) analise = JSON.parse(analise);
        if (
          analise &&
          analise.pontoCriticoId &&
          analise.pontoCriticoId == this.ponto.id
        )
          dialogRef.componentInstance.analise = analise.economico;
      },
      (error) => {
        console.log(error);
        this.loadingService.loading(false);
      }
    );
  }

  tratar(input: any) {
    if (!input.base64.includes("data:image/jpeg;base64")) {
      return (
        "data:image/jpeg;base64," +
        input.base64.replace('"', "").replace('"', "")
      );
    } else {
      return input.base64;
    }
  }

  openPhotos() {
    this.close();
    this.loadingService.loading(true);
    this.imagemService.listFindPontoCritico(this.ponto.id).subscribe(
      (r) => {
        if (r != null) {
          // localStorage.setItem('photos', JSON.stringify(r));
          this.loadingService.loading(false);
          if (r.length > 0) {
            const dialogRef = this.dialog.open(DialogImageComponent, {
              height: "auto",
              width: "auto",
              panelClass: "custom-dialog",
              disableClose: true,
            });
            dialogRef.componentInstance.images = r.map((f) => {
              return {
                image: this.tratar(f),
                informante: f.ponte.dados.responsavelPelaColeta,
                data: f.ponte.dados.dataHora,
              };
            });
          } else {
            this.loadingService.loading(false);
            this.alert.openAlert("Esta Ponte não possui fotos.");
          }
        } else {
          this.loadingService.loading(false);
          this.alert.openError(
            "Ocorreu um Erro, não foi possivel carregar as fotos! Tente novamente mais tarde..."
          );
        }
      },
      (error) => {
        console.log(error);
        this.loadingService.loading(false);
        this.alert.openError(
          "Ocorreu um Erro, não foi possivel carregar as fotos! Tente novamente mais tarde..."
        );
      }
    );
  }

  alterPublic(event) {
    this.ponto.publico = event.checked;
    this.pontoService
      .alterPublic({ id: this.ponto.id, isPublic: event.checked })
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }
}
