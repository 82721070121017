import { Message } from './../../layouts/components/message/message.component';
import { Component, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";

@Component({
    selector: 'app-dialog-message',
    templateUrl: 'dialog-message.html',
    styleUrls: ['dialog-message.css']
})
export class DialogMessage {

    @Output() onOk = new EventEmitter();
    message: Message;

    constructor(
        public dialogRef: MatDialogRef<DialogMessage>,
        public dialog: MatDialog
    ) { }

    close() {
        this.dialogRef.close();
    }

}