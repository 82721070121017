import {
  Component,
} from '@angular/core';


@Component({
  selector: 'app-sidebar-context',
  templateUrl: './sidebar-context.component.html'
})
export class SidebarContextComponent {
  
  constructor( ) { }

}
