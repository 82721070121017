import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-image',
  templateUrl: './dialog-image.component.html',
  styleUrls: ['./dialog-image.component.css']
})
export class DialogImageComponent implements OnInit {
  images = [];
  index = 0;
  image: any;

  constructor(
    public dialogRef: MatDialogRef<DialogImageComponent>
  ) { }


  ngOnInit() {
    if (this.images.length > 0) this.image = this.images[0];

    document.onkeydown = (evt) => {
      if (evt.key == "ArrowRight") this.next();
      if (evt.key == 'ArrowLeft') this.back();
      if (evt.key == 'Escape') this.close();
    };
  }

  next() {
    if (this.index < this.images.length - 1) {
      this.index++;
      this.image = this.images[this.index];
    }
  }

  back() {
    if (this.index > 0) {
      this.index--;
      this.image = this.images[this.index];
    }
  }

  close() {
    this.dialogRef.close();
  }

}
