import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take } from 'rxjs/operators';

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class GeoService {

    constructor(private http: HttpClient) {}

    getCar(latitude, longitude): Observable<any> {
        return this.http.post(`${SERVICE}/geo/car`, {'latitude': latitude, 'longitude': longitude}).pipe(take(1));
    }
    
    getRodovia(): Observable<any> {
        return this.http.get(`${SERVICE}/geo/rodovia`).pipe(take(1));
    }
    
}