import { UsuarioService } from 'src/app/services/usuario.service';
import {
  Component, OnInit,
} from '@angular/core';


@Component({
  selector: 'app-menu-context',
  templateUrl: './menu-context.component.html'
})
export class MenuContextComponent implements OnInit {

  admin = false;

  constructor(
    private usuarioService: UsuarioService
  ) { }


  ngOnInit() {
    this.getUsuarioLogado();
    this.usuarioService.permissoesAlteradas.subscribe(r => {
      this.getUsuarioLogado();
    });
  }

  getUsuarioLogado() {
    this.usuarioService.getUsuarioLogado().subscribe(r => {
      this.admin = r.admin;
    });
  }

}
