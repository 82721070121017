import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FullComponent } from './layouts/full/full.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { MessageComponent } from './layouts/components/message/message.component';
import { NotificationComponent } from './layouts/components/notification/notification.component';
import { HeaderComponent } from './layouts/header/header.component';
import { MenuComponent } from './layouts/components/menu/menu.component';
import { MapaComponent } from './mapa/mapa.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatTableModule,
  MatPaginatorModule,
  MatCardModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatButtonModule,
  MatIconModule,
  MatPaginatorIntl,
  MatCheckboxModule,
  MatSortModule,
  MatTooltipModule
} from '@angular/material';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppBreadcrumbComponent } from './layouts/breadcrumb/breadcrumb.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { getPortuguesePaginatorIntl } from './pt-br-paginator-intl';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { SidebarContextComponent } from './layouts/sidebar-context/sidebar-context.component';
import { MenuContextComponent } from './layouts/components/menu-context/menu-context.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { ConfiguracaoComponent } from './layouts/components/configuracao/configuracao.component';
import { PontoComponent } from './ponto/ponto.component';
import { AuthInterceptor } from './http-client-interceptor';
import { MapaPublicComponent } from './mapa-public/mapa-public.component';
import { AcessoComponent } from './acesso/acesso.component';
import { ForgotComponent } from './forgot/forgot.component';

registerLocaleData(pt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppBreadcrumbComponent,
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    ResetPasswordComponent,
    SidebarComponent,
    SidebarContextComponent,
    HeaderComponent,
    MenuComponent,
    MenuContextComponent,
    NotificationComponent,
    ConfiguracaoComponent,
    MessageComponent,
    FooterComponent,
    PontoComponent,
    MapaComponent,
    MapaPublicComponent,
    AcessoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonModule,
    MatNativeDateModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl()
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
