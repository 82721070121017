import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AlertService } from 'src/app/services/alert.service';
import { ImagemService } from 'src/app/services/imagem.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { DialogImagePerfil } from 'src/app/shared/dialog-image-perfil/dialog-image-perfil.component';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html'
})
export class ConfiguracaoComponent implements OnInit {

  @Output() newImageEmitter = new EventEmitter();
  @Input() email;
  @Input() imagem = {
    id: '',
    conteudo: '/assets/images/profile2.png'
  }

  constructor(
    private dialog: MatDialog,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() { }

  openImage() {
    const dialogRef = this.dialog.open(DialogImagePerfil, {
      height: 'auto',
      width: 'auto',
      maxHeight: '90%',
      panelClass: 'custom-dialog'
    });

    dialogRef.componentInstance.imagem = this.imagem;
    dialogRef.componentInstance.showButton = true;

    dialogRef.componentInstance.onOk.subscribe(r => {
      if (r.obj) {
        this.imagem.conteudo = r.obj;
        this.usuarioService.saveImageUsuario(this.email, this.imagem).subscribe(() => {
          this.newImageEmitter.emit();
        });
      } else {
        this.imagem.conteudo = 'assets/images/profile2.png';
        if (this.imagem.id) {
          this.usuarioService.deleteImageUsuario(this.email, this.imagem.id).subscribe(() => {
            this.imagem.id = '';
            this.newImageEmitter.emit();
          });
        }
      }
      r.dialog.close();
    });
  }

}