import {
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSort,
} from "@angular/material";
import Swal from "sweetalert2";
import "moment";
import { Router } from "@angular/router";
import { PontoService } from "../services/ponto.service";
import { AlertService } from "../services/alert.service";
import { ImagemService } from "../services/imagem.service";
import { LoadingService } from "../services/loading.service";
import { UsuarioService } from "../services/usuario.service";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DialogImageComponent } from "../shared/dialog-image/dialog-image.component";
import { DialogDetalhePonto } from "../shared/dialog-detalhe-ponto/dialog-detalhe-ponto";
import * as moment from "moment";

@Component({
  selector: "app-ponto",
  templateUrl: "./ponto.component.html",
  styleUrls: ["./ponto.component.css"],
})
export class PontoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pontes: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = [
    "dataPrimeiroRelato",
    "dataUltimoRelato",
    "qtdInfo",
    "tipo",
    "nomeTrecho",
    "municipio",
    "acoes",
  ];

  inicio: Date;
  fim: Date;
  txtPontesEmpty = "";
  tipoInformante;
  rodovia;
  municipio;
  txtMunicipio = "";

  param;

  municipios = [];
  rodovias = [];
  identificacoes = [
    "Produtor Rural",
    "Transportador de Carga",
    "Família",
    "Grupo FAEG JOVEM",
    "Instrutor/Técnico SENAR",
    "Outro",
  ];

  editor = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private alert: AlertService,
    private pontoService: PontoService,
    private _change: ChangeDetectorRef,
    private imagemService: ImagemService,
    private loadingService: LoadingService,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {
    localStorage.removeItem("ponto");
    this.pontes.paginator = this.paginator;
    this.pontes.sort = this.sort;
    this.setDate();
    this.listPontos();
    this.listRodovias();
    this.getUsuarioLogado();

    this._change.markForCheck();
  }

  getUsuarioLogado() {
    this.usuarioService.getUsuarioLogado().subscribe((r) => {
      this.editor = r.editor;

      if (this.editor) {
        this.displayedColumns = [
          "dataPrimeiroRelato",
          "dataUltimoRelato",
          "qtdInfo",
          "tipo",
          "nomeTrecho",
          "municipio",
          "publico",
          "acoes",
        ];
      }
    });
  }

  listRodovias() {
    this.pontoService.listRodovias().subscribe((response) => {
      this.rodovias = response;
    });
  }

  setDate() {
    this.inicio = moment().startOf("month").toDate();
    this.fim = moment().toDate();
  }

  clearFiltro() {
    this.setDate();
    this.tipoInformante = undefined;
    this.rodovia = undefined;
    this.municipio = undefined;
    this.txtMunicipio = "";
    this.listPontos();
  }

  listPontos() {
    this.loadingService.loading(true);

    let dataInicial = this.inicio
      ? moment(this.inicio).format("YYYY-MM-DD")
      : null;

    let dataFinal = this.fim
      ? moment(this.fim).set("hour", 23).format("YYYY-MM-DD")
      : null;

    this.param = {
      fim: dataFinal,
      inicio: dataInicial,
      rodovia: this.rodovia ? this.rodovia : null,
      tipo: this.tipoInformante ? this.tipoInformante : null,
      municipioId: this.municipio ? this.municipio.id : null,
    };

    if (this.param) {
      this.pontoService.filterPontos(this.param).subscribe(
        (r) => {
          if (r && r.length > 0) {
            this.txtPontesEmpty = "";

            r.forEach((i) => {
              if (i.municipio) {
                i.municipio =
                  i.municipio.nome +
                  " - " +
                  i.municipio.uf +
                  " (IBGE: " +
                  i.municipio.ibge +
                  ")";
              } else {
                i.municipio = "N/A";
              }

              if (!i.nomeTrecho) {
                i.nomeTrecho = "N/A";
              }
            });
          } else {
            this.txtPontesEmpty = "Nenhum dado encontrado.";
          }
          this.pontes.data = r;

          localStorage.setItem("pontos", JSON.stringify(r));

          this.loadingService.loading(false);
        },
        (error) => {
          console.log(error);

          this.loadingService.loading(false);

          this.alert.openError(
            "Ocorreu um Erro ao buscar os dados! Tente novamente mais tarde..."
          );
        }
      );
    }
  }

  alterPublic(event, ponto) {
    ponto.publico = event.checked;
    this.pontoService
      .alterPublic({ id: ponto.id, isPublic: event.checked })
      .subscribe();
  }

  setMunicipio(municipio) {
    this.municipio = municipio;
    this.txtMunicipio = municipio.nome + " - " + municipio.uf;
  }

  filterMunicipios(munDesc) {
    if (munDesc.value.length > 2) {
      this.pontoService.filterMunicipio(munDesc.value).subscribe((r) => {
        let mun = this.txtMunicipio.slice(0, this.txtMunicipio.indexOf(" - "));
        let uf = this.txtMunicipio.slice(this.txtMunicipio.indexOf(" - ") + 3);
        this.municipios = r.filter((i) => i.nome != mun || i.uf != uf);
      });
    }
  }

  exportExcel() {
    this.loadingService.loading(true);
    this.pontoService.exportExcel(this.param).subscribe(
      (r) => {
        var url = window.URL.createObjectURL(new Blob([r.body]));
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `pontos${
          this.param.inicio ? "_" + this.refactorDate(this.param.inicio) : ""
        }${
          this.param.fim ? "_" + this.refactorDate(this.param.fim, true) : ""
        }${this.param.tipo ? "_" + this.param.tipo : ""}${
          this.municipio
            ? "_" + this.municipio.nome + "-" + this.municipio.uf
            : ""
        }.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.loadingService.loading(false);
      },
      (error) => {
        console.log(error);
        this.loadingService.loading(false);
      }
    );
  }

  exportDoc() {
    this.loadingService.loading(true);
    this.pontoService.exportDoc(this.param).subscribe(
      (r) => {
        var url = window.URL.createObjectURL(new Blob([r.body]));
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `pontos${
          this.param.inicio ? "_" + this.refactorDate(this.param.inicio) : ""
        }${
          this.param.fim ? "_" + this.refactorDate(this.param.fim, true) : ""
        }${this.param.tipo ? "_" + this.param.tipo : ""}${
          this.municipio
            ? "_" + this.municipio.nome + "-" + this.municipio.uf
            : ""
        }.docx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.loadingService.loading(false);
      },
      (error) => {
        console.log(error);
        this.loadingService.loading(false);
      }
    );
  }

  refactorDate(stringDate: String, menoUm?: boolean) {
    if (stringDate) {
      let ano = stringDate.substring(0, stringDate.indexOf("-"));
      stringDate = stringDate.substring(
        stringDate.indexOf("-") + 1,
        stringDate.length
      );

      let mes = stringDate.substring(0, stringDate.indexOf("-"));
      if (mes.length == 1) mes = `0${mes}`;

      let dia = stringDate.substring(
        stringDate.indexOf("-") + 1,
        stringDate.length
      );
      if (menoUm) dia = String(Number(dia) - 1);
      if (dia.length == 1) dia = `0${dia}`;
      return `${dia}-${mes}-${ano}`;
    }
    return "";
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pontes.filter = filterValue.trim().toLowerCase();
  }

  openDetails(ponto) {
    this.loadingService.loading(true);
    this.pontoService.listPontesFindPontoCritico(ponto.id).subscribe(
      (p) => {
        this.loadingService.loading(false);
        const dialogRef = this.dialog.open(DialogDetalhePonto, {
          height: "auto",
          minWidth: "70%",
        });
        dialogRef.componentInstance.ponto = ponto;
        dialogRef.componentInstance.relatos = p;

        let analise: any = localStorage.getItem("analise");
        if (analise != null) analise = JSON.parse(analise);
        if (
          analise &&
          analise.pontoCriticoId &&
          analise.pontoCriticoId == ponto.id
        )
          dialogRef.componentInstance.analise = analise.economico;
      },
      (error) => {
        this.loadingService.loading(false);
        console.log(error);
      }
    );
  }

  openMap(ponto) {
    localStorage.setItem("ponto", JSON.stringify(ponto));
    this.router.navigate(["/mapa/ponto-detalhe"]);
  }

  delete(ponto) {
    Swal.fire({
      title: "Atenção!",
      text: "Você realmente quer excluir este Ponto?",
      showCancelButton: true,
      confirmButtonText: "Sim quero!",
      cancelButtonText: "Cancelar",
    }).then((r) => {
      if (r.value) {
        this.loadingService.loading(true);
        this.pontoService.deletePontoCritico(ponto.id).subscribe(
          (r) => {
            this.loadingService.loading(false);
            this.listPontos();
          },
          (error) => {
            this.loadingService.loading(false);
            console.log(error);
          }
        );
      }
    });
  }

  tratar(input: any) {
    if (!input.base64.includes("data:image/jpeg;base64")) {
      return (
        "data:image/jpeg;base64," +
        input.base64.replace('"', "").replace('"', "")
      );
    } else {
      return input.base64;
    }
  }

  openPhotos(ponto) {
    this.loadingService.loading(true);

    this.imagemService.listFindPontoCritico(ponto.id).subscribe(
      (r) => {
        if (r != null) {
          this.loadingService.loading(false);
          if (r.length > 0) {
            const dialogRef = this.dialog.open(DialogImageComponent, {
              height: "auto",
              width: "auto",
              panelClass: "custom-dialog",
              disableClose: true,
            });

            dialogRef.componentInstance.images = r.map((f) => {
              return {
                image: this.tratar(f),
                informante: f.ponte.dados.responsavelPelaColeta,
                data: f.ponte.dados.dataHora,
              };
            });
          } else {
            this.loadingService.loading(false);
            this.alert.openAlert("Esta Ponte não possui fotos.");
          }
        } else {
          this.loadingService.loading(false);
          this.alert.openError(
            "Ocorreu um Erro, não foi possivel carregar as fotos! Tente novamente mais tarde..."
          );
        }
      },
      (error) => {
        console.log(error);
        this.loadingService.loading(false);
        this.alert.openError(
          "Ocorreu um Erro, não foi possivel carregar as fotos! Tente novamente mais tarde..."
        );
      }
    );
  }
}
