import { AlertService } from './../services/alert.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RegisterService } from '../services/register.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';

const nome = new FormControl('', Validators.required);
const sobrenome = new FormControl('');
const email = new FormControl('', Validators.required);
const password = new FormControl('', Validators.required);
const confirmPassword = new FormControl('', CustomValidators.equalTo(password));
const readTerms = new FormControl('', Validators.required);

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  flag = true;

  constructor(
    private alert: AlertService,
    private fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router,
  ) { }

  cadastroDto: any = {
    usuario: {
      nome: '',
      sobrenome: '',
      login: '',
    },
    user: {
      password: '',
      login: '',
      roles: [],
      extra: {
        nome: '',
        sobrenome: '',
      }
    }
  };

  ngOnInit() {
    this.form = this.fb.group({
      nome: nome,
      sobrenome: sobrenome,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      readTerms: readTerms
    });
  }

  onButton() {
    this.flag = true;
  }

  criarConta() {
    this.cadastroDto.usuario.nome = this.form.value.nome;
    this.cadastroDto.usuario.sobrenome = this.form.value.sobrenome;
    this.cadastroDto.usuario.login = this.form.value.email;
    this.cadastroDto.user.password = this.form.value.password;
    this.cadastroDto.user.login = this.form.value.email;
    this.cadastroDto.user.extra.nome = this.form.value.nome;
    this.cadastroDto.user.extra.sobrenome = this.form.value.sobrenome;
    this.cadastroDto.user.roles.push("ROLE_USER");

    this.registerService.save(this.cadastroDto).subscribe(r => {
      if (r.result == "OK" || r.message == this.form.value.email + " already exists") {
        this.form = this.fb.group({
          nome: nome,
          sobrenome: sobrenome,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          readTerms: readTerms
        });
        this.alert.openSuccess('Conta criada com sucesso');
        localStorage.setItem('email', this.form.value.email);
        this.router.navigate(['/login']);
      } else {
        if (r.result == "Email já cadastrado") {
          document.getElementById("inputEmail").focus();
          this.alert.openError('Email já cadastrado');
        }
      }
    });
  }

}
