import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { take } from "rxjs/operators";

const SERVICE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class MensagemService {

    constructor(private http: HttpClient){}

    show(email): Observable<any> {
        return this.http.get(`${SERVICE}/mensagem/listar/${email}`).pipe(take(1));
    }
    
    ler(mensagemId): Observable<any> {
        return this.http.get(`${SERVICE}/mensagem/ler/${mensagemId}`).pipe(take(1));
    }

}