import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnInit {

  scripts = [
    "dsgov",
    // "all",
  ]

  title = 'rodopontes-teste';
  loadAPI;

  ngOnInit() {
    this.loadAPI = new Promise(resolve => {
      this.loadScript();
    });
  }

  public loadScript() {
    console.warn('script success');
    this.scripts.forEach((value, i) => {
      let node = document.createElement("script");
      node.src = `assets/${value}.min.js`;
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    });
  }

}
